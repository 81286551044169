.waypoints {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-column: 1/3;

  margin-top: 10px;
}

.select {
  width: 100%;
}
