.separator-control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: col-resize;
  width: 5px;
  user-select: none;
}
