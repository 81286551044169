.order-list {
  position: relative;
  z-index: 2;
  width: 415px;
  min-width: 300px;
  min-height: 100vh;
  box-shadow: 2px 0 2px 0 rgb(0 0 0 / 10%);

  --heading-height: 80px;
  --not-active-color: #f0f0f0;
  --active-color: #fff;
}

.order-list__heading {
  position: sticky;
  top: 0;
  padding: 20px 15px;
  margin: 0;
  font-size: 2em;
  font-weight: 700;
  border-bottom: 1px solid #bdbdbd;
  height: var(--heading-height);
  background-color: var(--not-active-color);
}

.order-list__items {
  overflow: auto;
  height: calc(100vh - var(--heading-height));
  font-size: 1em;
  font-weight: 500;
}

.order-list__item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr min-content;
  grid-column-gap: 20px;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding: 20px 15px;
  min-width: 400px;
  min-height: 90px;
  cursor: pointer;
  text-overflow: clip;
  user-select: none;
  background-color: var(--not-active-color);
}

.order-list__item--active {
  background-color: var(--active-color);
}


